import { render, staticRenderFns } from "./InputPBJ.vue?vue&type=template&id=4cc28532&scoped=true&"
import script from "./InputPBJ.vue?vue&type=script&lang=js&"
export * from "./InputPBJ.vue?vue&type=script&lang=js&"
import style0 from "./InputPBJ.vue?vue&type=style&index=0&id=4cc28532&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cc28532",
  null
  
)

export default component.exports