<template>
<v-container fluid>
  <v-row>
    <v-col>
      <base-material-card icon="mdi-account-group">
        <template v-slot:heading>
          <h3 class="display-2">Input Pengadaan Barang dan Jasa</h3>
        </template>

        <crud
          :crudSettings="crudSettings"
          :headers="selectedJenisPBJ == 'barjas_pokja' || selectedJenisPBJ == 'pbj' ? headersInputPBJ : headersInputPPK"
          @onTableChange="updateTableInputPBJ"
          :responseData="itemsDataPBJ"
          @onEditClick="editItem"
          @onDeleteClick="deleteItem"
        >

        <template v-slot:append-header>
          <v-col cols="9" >
            <v-select
              class="float-left"
              item-text="b"
              item-value='k'
              :items="bulanList"
              label="Pilih Bulan"
              dense
              @change="filterBulan"
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-btn color="green" @click="createItem()" small dark class="mx-1"><v-icon small left>mdi-plus</v-icon> Tambah Laporan Barang dan Jasa</v-btn>
          </v-col>
        </template>

        </crud>

      </base-material-card>
    </v-col>
  </v-row>

  <!-- The following below is the form for adding PBJ report -->
  <v-dialog v-model="dialogTambahDataPBJ" max-width="600px">
    <v-card>
      <v-card-text>
        <v-form ref="formTambah">
          <h1 class="display-2 my-2">Lapor Barang dan Jasa</h1>
          <!-- <v-spacer></v-spacer> -->
          <v-select dense v-model="selectedJenisPBJ" :items="listJenisPBJ" @change="jenisPBJ()" item-text="text" item-value="value" label="Jenis PBJ" outlined ></v-select>

          <v-text-field v-show="showItem" v-model="formEdit.nospk" :rules="showItem == true ? [rules.required] : [rules.allowed]" dense label="No. Berita Acara Pemenang" outlined ></v-text-field>
          <v-text-field v-show="!showItem" v-model="formEdit.nospk" :rules="!showItem == false ? [rules.required] : [rules.allowed]" dense label="No. SPK" outlined ></v-text-field>

          <v-menu
            v-model="fromDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            min-width="290px"
            transition="scale-transition"
            lazy offset-y full-width>
            <template v-slot:activator="{ on }">
              <!-- :value="fromDateDisp" -->
              <v-text-field v-show="showItem" label="Tanggal Berita Acara Pemenang" :rules="showItem == true ? [rules.required] : [rules.allowed]" append-icon="mdi-calendar" outlined dense readonly v-model="fromDateVal" v-on="on"></v-text-field>
              <v-text-field v-show="!showItem" label="Tanggal" :rules="!showItem == false ? [rules.required] : [rules.allowed]" append-icon="mdi-calendar" outlined dense readonly v-model="fromDateVal" v-on="on"></v-text-field>
            </template>
            <v-date-picker
            locale="in-ID"
            :min="getStartDate" :max="getEndDate"
            v-model="fromDateVal"
            no-title
            @input="fromDateMenu = false"
            >
            </v-date-picker>
          </v-menu>

          <!-- <v-text-field v-show="showItem" :rules="[rules.required]" type="datetime-local" dense label="Tanggal Berita Acara Pemenang" outlined single-line></v-text-field>
          <v-text-field v-show="!showItem" v-model="formEdit.tgl" :rules="[rules.required]" type="datetime-local" dense label="Tanggal" outlined ></v-text-field> -->

          <v-text-field v-show="showItem" v-model="formEdit.paket_pekerjaan" :rules="showItem == true ? [rules.required] : [rules.allowed]" dense label="Paket Pekerjaan" outlined ></v-text-field>

          <v-autocomplete dense v-show="showItem" v-model="formEdit.paket_dinas" :items="listSKPD" :rules="showItem == true ? [rules.required] : [rules.allowed]" item-text="text" item-value="value" label="Paket Dinas" outlined ></v-autocomplete>

          <v-text-field v-model="formEdit.pagu" :rules="[rules.required, rules.onlyInt]" dense outlined label="Nilai Kontrak"></v-text-field>

          <file-input v-show="showItem" :helpText="'Evidence / Bukti Berita Acara Pemenang'" ref="fileInput" :errorInput="fileInputErrorFlag" :uploadUrl="uploadUrl" @delFileUploaded="delFormFile" @fileUploaded="addFormFile"></file-input>
          <file-input v-show="!showItem" :helpText="'File'" ref="fileInput" :errorInput="fileInputErrorFlag" :uploadUrl="uploadUrl" @delFileUploaded="delFormFile" @fileUploaded="addFormFile"></file-input>

        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="tutup()">Tutup</v-btn>
        <v-btn color="success" @click="simpanItem()">Simpan</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</v-container>
</template>

<script>
import _ from 'lodash'
import store from '@/store'
import Crud from '@/components/common/Crud'
import InputPBJService from '@/services/InputPBJService'
import FileInput from '@/components/common/FileInputPPNS'
import {defaultRules} from '@/utils/lib'
import axios from 'axios'
import _g from '../../global'

export default {
  components:{
    Crud,
    FileInput
  },

  data(){
    return{
      fromDateMenu: false,
      fromDateVal: null,
      date: new Date(), 
      dialogTambahDataPBJ:false,
      fileInputErrorFlag:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        deleteTitleKey:'no_surat',
        enableHeader:false,
        enableDetail:false,
        enableCreate:false,
        enableEdit:true,
        enableDelete:true,
      },
      listJenisPBJ: [
        {value: 'barjas_pokja', text: 'Perancang Kebijaksan Pengadaan Barang/Jasa'},
        {value: 'barjas_skpd', text: 'Pranata Barang dan Jasa'},
        {value: 'ppk', text: 'Pejabat Pembuat Komitmen'},
        {value: 'pbj', text: 'Pejabat Pengadaan Barang/Jasa'},
        {value: 'pphp', text: 'Pejabat Penerima Hasil Pekerjaan'},
        {value: 'pranata_barjas', text: 'Pranata Barang dan Jasa'}
      ],
      listSKPD: [
        {value:"Badan Kepegawaian dan Pengembangan Sumber Daya Manusia", text: "Badan Kepegawaian dan Pengembangan Sumber Daya Manusia"},
        {value:"Badan Kesatuan Bangsa dan Politik", text: "Badan Kesatuan Bangsa dan Politik"},
        {value:"Badan Keuangan dan Aset Daerah", text: "Badan Keuangan dan Aset Daerah"},
        {value:"Badan Pendapatan Daerah", text: "Badan Pendapatan Daerah"},
        {value:"Badan Perencanaan Pembangunan, Penelitian dan Pengembangan", text: "Badan Perencanaan Pembangunan, Penelitian dan Pengembangan"},
        {value:"Dinas Arsip dan Perpustakaan", text: "Dinas Arsip dan Perpustakaan"},
        {value:"Dinas Kebakaran dan Penanggulangan Bencana", text: "Dinas Kebakaran dan Penanggulangan Bencana"},
        {value:"Dinas Kebudayaan dan Pariwisata", text: "Dinas Kebudayaan dan Pariwisata"},
        {value:"Dinas Kependudukan dan Pencatatan Sipil", text: "Dinas Kependudukan dan Pencatatan Sipil"},
        {value:"Dinas Kesehatan", text: "Dinas Kesehatan"},
        {value:"Dinas Ketahanan Pangan dan Pertanian", text: "Dinas Ketahanan Pangan dan Pertanian"},
        {value:"Dinas Ketenagakerjaan", text: "Dinas Ketenagakerjaan"},
        {value:"Dinas Komunikasi dan Informatika", text: "Dinas Komunikasi dan Informatika"},
        {value:"Dinas Koperasi dan Usaha Kecil dan Menengah", text: "Dinas Koperasi dan Usaha Kecil dan Menengah"},
        {value:"Dinas Lingkungan Hidup dan Kebersihan", text: "Dinas Lingkungan Hidup dan Kebersihan"},
        {value:"Dinas Pekerjaan Umum", text: "Dinas Pekerjaan Umum"},
        {value:"Dinas Pemberdayaan Perempuan dan Perlindungan Anak", text: "Dinas Pemberdayaan Perempuan dan Perlindungan Anak"},
        {value:"Dinas Pemuda dan Olahraga", text: "Dinas Pemuda dan Olahraga"},
        {value:"Dinas Penanaman Modal dan Pelayanan Terpadu Satu Pintu", text: "Dinas Penanaman Modal dan Pelayanan Terpadu Satu Pintu"},
        {value:"Dinas Penataan Ruang", text: "Dinas Penataan Ruang"},
        {value:"Dinas Pendidikan", text: "Dinas Pendidikan"},
        {value:"Dinas Pengendalian Penduduk dan Keluarga Berencana", text: "Dinas Pengendalian Penduduk dan Keluarga Berencana"},
        {value:"Dinas Perdagangan dan Perindustrian", text: "Dinas Perdagangan dan Perindustrian"},
        {value:"Dinas Perhubungan", text: "Dinas Perhubungan"},
        {value:"Dinas Perumahan dan Kawasan Permukiman, Pertanahan dan Pertamanan", text: "Dinas Perumahan dan Kawasan Permukiman, Pertanahan dan Pertamanan"},
        {value:"Dinas Sosial", text: "Dinas Sosial"},
        {value:"Inspektorat Daerah", text: "Inspektorat Daerah"},
        {value:"Kecamatan Andir", text: "Kecamatan Andir"},
        {value:"Kecamatan Antapani", text: "Kecamatan Antapani"},
        {value:"Kecamatan Arcamanik", text: "Kecamatan Arcamanik"},
        {value:"Kecamatan Astana Anyar", text: "Kecamatan Astana Anyar"},
        {value:"Kecamatan Babakan Ciparay", text: "Kecamatan Babakan Ciparay"},
        {value:"Kecamatan Bandung Kidul", text: "Kecamatan Bandung Kidul"},
        {value:"Kecamatan Bandung Kulon", text: "Kecamatan Bandung Kulon"},
        {value:"Kecamatan Bandung Wetan", text: "Kecamatan Bandung Wetan"},
        {value:"Kecamatan Batununggal", text: "Kecamatan Batununggal"},
        {value:"Kecamatan Bojongloa Kaler", text: "Kecamatan Bojongloa Kaler"},
        {value:"Kecamatan Bojongloa Kidul", text: "Kecamatan Bojongloa Kidul"},
        {value:"Kecamatan Buahbatu", text: "Kecamatan Buahbatu"},
        {value:"Kecamatan Cibeunying Kaler", text: "Kecamatan Cibeunying Kaler"},
        {value:"Kecamatan Cibeunying Kidul", text: "Kecamatan Cibeunying Kidul"},
        {value:"Kecamatan Cibiru", text: "Kecamatan Cibiru"},
        {value:"Kecamatan Cicendo", text: "Kecamatan Cicendo"},
        {value:"Kecamatan Cidadap", text: "Kecamatan Cidadap"},
        {value:"Kecamatan Cinambo", text: "Kecamatan Cinambo"},
        {value:"Kecamatan Coblong", text: "Kecamatan Coblong"},
        {value:"Kecamatan Gedebage", text: "Kecamatan Gedebage"},
        {value:"Kecamatan Kiaracondong", text: "Kecamatan Kiaracondong"},
        {value:"Kecamatan Lengkong", text: "Kecamatan Lengkong"},
        {value:"Kecamatan Mandalajati", text: "Kecamatan Mandalajati"},
        {value:"Kecamatan Panyileukan", text: "Kecamatan Panyileukan"},
        {value:"Kecamatan Rancasari", text: "Kecamatan Rancasari"},
        {value:"Kecamatan Regol", text: "Kecamatan Regol"},
        {value:"Kecamatan Sukajadi", text: "Kecamatan Sukajadi"},
        {value:"Kecamatan Sukasari", text: "Kecamatan Sukasari"},
        {value:"Kecamatan Sumur Bandung", text: "Kecamatan Sumur Bandung"},
        {value:"Kecamatan Ujungberung", text: "Kecamatan Ujungberung"},
        {value:"Rumah Sakit Khusus Gigi dan Mulut", text: "Rumah Sakit Khusus Gigi dan Mulut"},
        {value:"Rumah Sakit Khusus Ibu dan Anak", text: "Rumah Sakit Khusus Ibu dan Anak"},
        {value:"Rumah Sakit Umum Daerah", text: "Rumah Sakit Umum Daerah"},
        {value:"Satuan Polisi Pamong Praja", text: "Satuan Polisi Pamong Praja"},
        {value:"Sekretariat Daerah", text: "Sekretariat Daerah"},
        {value:"Sekretariat Dewan Perwakilan Rakyat Daerah", text: "Sekretariat Dewan Perwakilan Rakyat Daerah"},
        {value:"Sekretaris Daerah", text: "Sekretaris Daerah"},
        {value:"Kepala Bagian Administrasi Pembangunan", text: "Kepala Bagian Administrasi Pembangunan"},
        {value:"Kepala Bagian Hukum", text: "Kepala Bagian Hukum"},
        {value:"Kepala Bagian Kerja Sama", text: "Kepala Bagian Kerja Sama"},
        {value:"Kepala Bagian Kesejahteraan Rakyat dan Kemasyarakatan", text: "Kepala Bagian Kesejahteraan Rakyat dan Kemasyarakatan"},
        {value:"Kepala Bagian Organisasi", text: "Kepala Bagian Organisasi"},
        {value:"Kepala Bagian Pengadaan Barang dan Jasa", text: "Kepala Bagian Pengadaan Barang dan Jasa"},
        {value:"Kepala Bagian Perekonomian", text: "Kepala Bagian Perekonomian"},
        {value:"Kepala Bagian Perencanaan, Keuangan dan Kepegawaian", text: "Kepala Bagian Perencanaan, Keuangan dan Kepegawaian"},
        {value:"Kepala Bagian Protokol dan Komunikasi Pimpinan", text: "Kepala Bagian Protokol dan Komunikasi Pimpinan"},
        {value:"Kepala Bagian Tata Pemerintahan", text: "Kepala Bagian Tata Pemerintahan"},
        {value:"Kepala Bagian Umum", text: "Kepala Bagian Umum"}
      ],
      listHari: [
        {text:"-", value:0},
        {text:'Senin', value:1},
        {text:'Selasa', value:2},
        {text:'Rabu', value:3},
        {text:'Kamis', value:4},
        {text:'Jumat', value:5},
        {text:'Sabtu', value:6},
        {text:'Minggu',value:7}
      ],
      selectedJenisPBJ: null,
      headersInputPPK:[
        {text:'No', value:'no', sortable:false, class:'header-index-profile'},
        {text:'Tanggal BAP', value:'tanggal_', sortable:false, class:'header-index-profile'},
        {text:'No. BAP', value:'nospk', sortable:false, class:'header-index-profile'},
        {text:'Nilai Kontrak', value:'pagu', sortable:false, class:'header-index-profile'},
        // {text:'Paket Pekerjaan', value:'paket_pekerjaan', sortable:false, class:'header-index-profile'},
        // {text:'Paket Dinas', value:'paket_dinas', sortable:false, class:'header-index-profile'},
        {text:'Evidence/Bukti', value:'file', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile'},
      ],
      headersInputPBJ:[
        {text:'No', value:'no', sortable:false, class:'header-index-profile'},
        {text:'Tanggal BAP', value:'tanggal_', sortable:false, class:'header-index-profile'},
        {text:'No. BAP', value:'nospk', sortable:false, class:'header-index-profile'},
        {text:'Nilai Kontrak', value:'pagu', sortable:false, class:'header-index-profile'},
        {text:'Paket Pekerjaan', value:'paket_pekerjaan', sortable:false, class:'header-index-profile'},
        {text:'Paket Dinas', value:'paket_dinas', sortable:false, class:'header-index-profile'},
        {text:'Evidence/Bukti', value:'file', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile'},
      ],
      formEdit:{file:[],},
      bulanList:[
        { b:'Januari',k:1},
        { b:'Febuari',k:2},
        { b:'Maret',k:3},
        { b:'April', k:4},
        { b:'Mei', k:5},
        { b:'Juni', k:6},
        { b:'Juli', k:7},
        { b:'Agustus', k:8},
        { b:'September', k:9},
        { b:'Oktober', k:10},
        { b:'November', k:11},
        { b:'Desember', k:12},
      ],
      itemsDataPBJ: {},
      showItem:false,
      rules:{
        ...defaultRules
      },
      allowed: null
    }
  },
  computed:{
    fromDateDisp() {
      return this.fromDateVal;
      // format date, apply validations, etc. Example below.
      // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
    },
    getStartDate() {
      let startDate
      if(this.formEdit.tanggal != undefined){
        let tanggal_ = new Date(this.formEdit.tanggal+'T00:00:00')
        startDate = new Date(tanggal_.getFullYear(), tanggal_.getMonth()+1, 1)
      }else{
        startDate = new Date(this.date.getFullYear(), this.date.getMonth(), 1)
      }
      return startDate.getFullYear()+'-'+this.addZero(startDate.getMonth())+'-'+this.addZero(startDate.getDate())
    },
    getEndDate() {
      let endDate
      if(this.formEdit.tanggal != undefined){
        let tanggal_ = new Date(this.formEdit.tanggal+'T00:00:00')
        let last_date = new Date(tanggal_.getFullYear(), tanggal_.getMonth() + 1, 0)
        endDate = new Date(tanggal_.getFullYear(), tanggal_.getMonth(), last_date.getDate())
      }else{
        endDate = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0)
      }
      return endDate.getFullYear()+'-'+this.addZero(endDate.getMonth()+1)+'-'+this.addZero(endDate.getDate())
    },
    uploadUrl(){
      return process.env.VUE_APP_API_URL + '/api/mb-upload/pbj/'
    },
    parseFile(){
      if(!this.itemsDataPBJ.foto){
        return '-'
      }else{
        return JSON.parse(this.itemsDataPBJ.foto)
      }
    }
  },

  methods:{
    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    addFormFile(file){
      this.formEdit.file.push(file.name)
      console.log("added_file",this.formEdit.file)
    },
    delFormFile(file){
      let regex = /\d+_([\w\s-]+)-[\w\d]+\.(\w+)/
      console.log("file_", file.file != undefined ? file.file.name : file)
      let filename = file.name != undefined ? file.file.name : file
      this.formEdit.foto = this.formEdit.file.filter((val)=>{
        const match = val.match(regex)
        let curr_filename = match[1]+'.'+match[2]
        if (match){
          return filename != curr_filename
        }
        return false
      })
    },
    jenisPBJ(val = null){
      if(val == null){
        if(this.selectedJenisPBJ == 'barjas_pokja' || this.selectedJenisPBJ == 'pbj'){
          this.showItem = true;
        }else{
          this.showItem = false;
        }
      }else{
        if(val == 'barjas_pokja' || val == 'pbj'){
          return true;
        }else{
          return false;
        }
      }
    },
    parseFilename(filename){
      let parsedFilename = /\d+_(.*)-[\w\d]+(\.\w+)/g.exec(filename)
      try{
        return parsedFilename[1] + parsedFilename[2]
      } catch(e){
        return filename
      }
    },
    filterBulan(bulan_){
      console.log("bulan",bulan_)
      let bulan = bulan_
      this.updateTableInputPBJ(bulan)
    },
    simpanItem(){
      // var base_url = window.location.origin
      // let text = '-';
      // const current_date = new Date()
      // const date_last_allowed = new Date()
      // In Javascript, date start from 0
      // date_last_allowed.setFullYear(2022, 11, 19)
      // if(this.allowed == false || this.allowed == null){
      //   if (current_date > date_last_allowed) {
      //     text = 'Mohon maaf, hari pelaporan sudah ditutup.'
      //     this.dialogTambahDataPBJ=false
      //     this.editState = false
      //     window.location.href = base_url + '/#/pbj'
      //     return store.commit('snackbar/setSnack',{message: text, color:'warning'})
      //   }

      //   var current_hour_minutes = new Date();
      //   current_hour_minutes.setHours(current_hour_minutes.getHours(), current_hour_minutes.getMinutes(), current_hour_minutes.getMilliseconds());
      //   var last_minutes_allowed = new Date(current_hour_minutes);
      //   last_minutes_allowed.setHours(23);
      //   last_minutes_allowed.setMinutes(59);
      //   if(current_hour_minutes >= last_minutes_allowed) {
      //     text = 'Mohon maaf, waktu pelaporan sudah ditutup.'
      //     this.dialogTambahDataPBJ=false
      //     this.editState = false
      //     window.location.href = base_url + '/#/pbj'
      //     return store.commit('snackbar/setSnack',{message: text, color:'warning'})
      //   }
      // }

      // if (this.$refs.formTambah.validate()){
        // this.formEdit.is_pembalik = this.formEdit.is_pembalik === undefined ? false : this.formEdit.is_pembalik
        // this.formEdit.is_skp_iki = this.formEdit.is_skp_iki === undefined ? false : this.formEdit.is_skp_iki

        this.formEdit.tanggal = this.fromDateVal
        this.formEdit.jenis = this.selectedJenisPBJ
        // if(this.formEdit.tanggal.length > 10){
        //   this.formEdit.tanggal = this.formEdit.tanggal.substring(0,10)
        // }

        if(this.formEdit.file != undefined && this.formEdit.file != null && this.formEdit.file.length != null){
          if(this.formEdit.file.length > 0){
            if(this.formEdit.file.length > 0 && this.formEdit.file.length < 5){
              let _join = ""
              for (let i = 0; i < this.formEdit.file.length; i++) {
                _join += this.formEdit.file[i].replace(/[\[\]']+/g,'')+"," /* eslint-disable-line */
              }
              this.formEdit.file = "[" + _join.replace(/,(?=\s*$)/, '') + "]" //remove last comma
            }
          }else{
            return store.commit('snackbar/setSnack',{message: "Maaf, Anda Belum Melampirkan File.", color:'error'})
          }
        }else{
          // this.formEdit.tanggal = this.formEdit.tanggal.substring(0,10)
          if(this.selectedJenisPBJ == 'barjas_pokja' || this.selectedJenisPBJ == 'pbj'){
            return store.commit('snackbar/setSnack',{message: "Maaf, Anda Belum Melampirkan File Evidence/Bukti Berita Acara Pemenang.", color:'error'})
          }else{
            return store.commit('snackbar/setSnack',{message: "Maaf, Anda Belum Melampirkan File.", color:'error'})
          }
        }
        
        if(this.editState == true){
          InputPBJService.updateDataPBJ(this.formEdit, this.formEdit.id).then(response => {
            let res = response.data
            if (res.success){
              store.commit('snackbar/setSnack',{message: 'Laporan Pengadaan Berhasil Diubah.', color:'success'})
              this.editState = false
              this.dialogTambahDataPBJ = false
              this.$refs.fileInput.clearAll()
              this.updateTableInputPBJ()

              // //remove file array
              // for (let i = 0; i < this.formEdit.file.length; i++) {
              //   const el = this.formEdit.file[i];
              //   this.delFormFile(el)
              // }
            } else {
              this.editState = false
              store.commit('snackbar/setSnack',{message: res.message, color:'error'})
            }
          })
        }else{
          InputPBJService.storeDataPBJ(this.formEdit).then(response => {
            let res = response.data
            if (res.success){
              store.commit('snackbar/setSnack',{message: 'Berhasil Melaporkan Pengadaan.', color:'success'})
              this.dialogTambahDataPBJ = false
              this.$refs.fileInput.clearAll()
              this.updateTableInputPBJ()

              // //remove file array
              // for (let i = 0; i < this.formEdit.file.length; i++) {
              //   const el = this.formEdit.file[i];
              //   this.delFormFile(el)
              // }
            } else {
              store.commit('snackbar/setSnack',{message: res.message, color:'error'})
            }
          })
        }
      // }
    },
    updateTableInputPBJ(bulan_){
      if(bulan_ == undefined){
        bulan_ = (new Date()).getMonth()+1
      }
      console.log("bulan",bulan_)
      this.forceUpdate = false
      // this.tableValueTahunan = val ? val: this.tableValueTahunan
      this.loadingTahunan = true
      this.itemsDataPBJ = {}

      var current = this.$store.getters["user/current"]
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_get_data = base_url_api + 'v1/get-data-pbj'
      axios.post(url_get_data, {
        nip: current.username,
        bulan: bulan_,
        api: 1
      }).then(response => {
        console.log('tahunan',response.data)
        if(response.data.success){
          response.data.data.forEach(el => {
            el.tanggal_ = el.tanggal
          });
          this.itemsDataPBJ = response.data
          this.allowed = response.data.allowed
          console.log(this.itemsDataPBJ)
        }else{
          store.commit('snackbar/setSnack',{message: response.data.error, color:'error'})
        }
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })

      // InputPBJService.getDataPBJ({
      //   bulan:bulan_
      //   })
      // .then((response)=>{
      //   console.log('tahunan',response.data)
      //   response.data.data.forEach(el => {
      //     el.tanggal_ = this.getFullDateDay(el.tanggal)
      //   });
      //   this.itemsDataPBJ = response.data
      //   this.allowed = response.allowed
      //   console.log(this.itemsDataPBJ)
      // }).finally(()=>{
      //   this.loadingTahunan = false
      // })
    },

    getFullDateDay(tanggal = 0) {
        let tanggal_ = new Date(tanggal+'T00:00:00')
        return tanggal_.getDate()+" "+this.bulanList[tanggal_.getMonth()].b+" "+tanggal_.getFullYear();
    },

    clearForm(){
      this.formEdit = {}
      this.fromDateVal = null
      this.selectedJenisPBJ = null
      this.formEdit = {}
      this.formEdit.file=[]
      this.editState = false
      this.showItem = false
    },

    createItem(){
      this.clearForm()
      this.dialogTambahDataPBJ=true
      if (this.$refs.fileInput){
        this.$refs.fileInput.clearAll()
      }
      // this.formTambah.file=[]
      // this.$refs.formTambah.resetValidation()
      setTimeout(()=>{
        document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0
      },500)
    },
    editItem(item){
      if (this.$refs.fileInput){
        this.$refs.fileInput.clearAll()
      }
      this.formEdit = item; this.dialogTambahDataPBJ = true; this.formEdit.file=[]
      // let tanggal = this.formEdit.tanggal.substring(8,10); let bulan = this.formEdit.tanggal.substring(5,7); let tahun = this.formEdit.tanggal.substring(0,4)
      // let d = new Date(); let h = this.addZero(d.getHours()); let m = this.addZero(d.getMinutes()); //let s = this.addZero(d.getSeconds());
      // this.formEdit.tanggal = tanggal + "/" + bulan + "/" + tahun + " " + h + ":" + m
      // this.formEdit.tanggal = this.formEdit.tanggal + "T" + h + ":" + m
      // this.formEdit.tanggal = this.formEdit.tanggal + " " + h + ":" + m
      this.formEdit = _.clone(this.formEdit)
      this.fromDateVal = this.formEdit.tanggal
      this.selectedJenisPBJ = this.formEdit.jenis
      this.editState = true
      setTimeout(()=>{
        document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0
      },500)
    },
    deleteItem(item){
      this.formEdit = item
      InputPBJService.hapusDataPBJ(this.formEdit).then((response)=>{
        // let res = response.data
        if (response.status){
          store.commit('snackbar/setSnack',{message: 'Data Pengadaan Berhasil Dihapus!', color:'success'})
          // store.commit('snackbar/setSnack',{message: response.message, color:'success'})
          this.updateTableInputPBJ()
        }else {
          store.commit('snackbar/setSnack',{message: response.message, color:'error'})
        }
      })
    },
    tutup(){
      this.dialogTambahDataPBJ=false
      this.editState = false
      return this.updateTableInputPBJ()
    }

  },

  created(){
    this.allowed = null
    this.selectedJenisPBJ = this.$store.getters["user/data"].jenis_pbj != undefined ? this.$store.getters["user/data"].jenis_pbj : this.selectedJenisPBJ
    this.showItem = this.jenisPBJ(this.selectedJenisPBJ)
    switch (this.selectedJenisPBJ) {
      case 'barjas_skpd':
        this.listJenisPBJ = [
          {value: 'barjas_skpd', text: 'Pranata Barang dan Jasa'},
        ];
        break;
      case 'ppk':
        this.listJenisPBJ = [
          {value: 'ppk', text: 'Pejabat Pembuat Komitmen'},
        ];
        break;
      case 'pphp':
        this.listJenisPBJ = [
          {value: 'pphp', text: 'Pejabat Penerima Hasil Pekerjaan'},
        ];
        break;
      case 'pranata_barjas':
        this.listJenisPBJ = [
          {value: 'pranata_barjas', text: 'Pranata Barang dan Jasa'}
        ];
        break;
    
      default:
        this.listJenisPBJ = [
          {value: 'barjas_pokja', text: 'Perancang Kebijaksan Pengadaan Barang/Jasa'},
          {value: 'pbj', text: 'Pejabat Pengadaan Barang/Jasa'},
        ];
        break;
    }
    // let val = {itemsPerPage:10, page: 1}
    // this.updateTableInputPBJ(val);
    this.updateTableInputPBJ();
  },
}
</script>

<style lang="css" scoped>
.label{
  font-size:12px
}
</style>
