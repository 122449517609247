import request from '@/utils/request'
import store from '@/store'
import _g from '../../src/global'

const InputPPNSService = {
    getDataPBJ,
    storeDataPBJ,
    actionLogInsertDataPBJ,
    actionLogDeleteDataPBJ,
    uploadFileDataPBJ,
    updateDataPBJ,
    hapusDataPBJ
}

function getDataPBJ({
    // page,
    // perpage,
    // search,
    // order,
    // order_direction,
    bulan
  }) {
    var current = store.state.user.data
    let user = store.state.user
    if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
      current.username = _g.overrideWithPLT(user.current.role)
    }else{
      current.username = user.current.username
    }
    console.log('current', current)
    return request({
      url: '/pbj/list',
      method: 'post',
      data: {
        nip: current.username,
        bulan: bulan
        // page: page,
        // perpage: perpage ? perpage : 20,
        // search: search ? search : '',
        // order: order ? order : 'sasaran',
        // order_direction: order_direction ? order_direction : 'asc'
      }
    })
  }

function storeDataPBJ(data){
  var current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/pbj/store',
    method: 'post',
    data: {
      nip: current.username,
      "tanggal": data.tanggal,
      "nospk": data.nospk,
      "pagu": data.pagu,
      "jenis": data.jenis,
      "file": data.file,
      "paket_pekerjaan": data.paket_pekerjaan,
      "paket_dinas": data.paket_dinas
    }
  })
}

function updateDataPBJ(data, id){
  var current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/pbj/update',
    method: 'post',
    data: {
      id: id ? id : null,
      nip: current.username,
      "tanggal": data.tanggal,
      "nospk": data.nospk,
      "pagu": data.pagu,
      "jenis": data.jenis,
      "file": data.file,
      "paket_pekerjaan": data.paket_pekerjaan,
      "paket_dinas": data.paket_dinas
    }
  })
}

function hapusDataPBJ(data){
  var current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url: '/pbj/delete',
    method: 'post',
    data: {
      id: data.id ? data.id : null,
      nip: current.username,
    }
  })
}

function actionLogInsertDataPBJ(){

}

function actionLogDeleteDataPBJ(){

}

function uploadFileDataPBJ(){

}

export default InputPPNSService